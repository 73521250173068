import { useEffect, useState, memo } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ImageField, TextField, Text, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { StNextImage } from '../../../shared/NextImage.styles';
import { MobileNavListItem, MobileNavIconWrap } from '../HeaderNavigation.styles';
import { CallCenterParent, ICallCenter, ICallCenterContext } from './CallCenter.types';
import { StyledLink, StyledLinkMobile } from './CallCenter.style';
import { AppBarNavItem } from '../HeaderNavigation.styles';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import getCallCenterNumber from '../../../../store/selectors/getCallCenterNumber';

// Fallback values
const fallback = {
  phoneNo: {
    value: '',
  },
  icon: {
    value: {
      src: '',
      alt: '',
    },
  },
};

const CallCenter: React.FC<ICallCenter> = (props) => {
  const theme = useTheme();
  const { sitecoreContext } = useSitecoreContext();
  const callCenterNumber = getCallCenterNumber(sitecoreContext);
  const callCenter = sitecoreContext?.callcenter as ICallCenterContext;
  const icon: ImageField = callCenter?.callCenterIcon || fallback.icon;
  const ariaLabel = callCenter?.callCenterAriaLabel?.value || '';
  const [phoneNo, setPhoneNo] = useState<TextField>({
    value: '',
  });
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const [telLink, setTelLink] = useState<string>('');
  const [finalAriaLabel, setFinalAriaLabel] = useState<string>(String(ariaLabel));
  useEffect(() => {
    setTelLink(`tel:${callCenterNumber?.value || ''}`);
    setPhoneNo(callCenterNumber);
    setFinalAriaLabel(`${ariaLabel} ${callCenterNumber?.value}`);
  }, [callCenterNumber.value]);

  const DesktopNavBar = () => (
    <AppBarNavItem
      className="main-nav-btn"
      display="flex"
      data-testid="desktop-nav-bar"
      id="call-center"
      hoverColor={props?.hoverColor}
      sx={{
        marginRight: props?.isSubHeader ? (isTablet ? 0 : '3rem') : 0,
        marginLeft: props?.isSubHeader ? (isTablet ? 0 : '3rem') : 0,
        flexGrow: props?.isSubHeader ? 1 : 'unset',
        justifyContent: props?.isSubHeader
          ? props?.isNotDesktop
            ? 'flex-end'
            : 'center'
          : 'space-between',
        flexFlow: isTablet && props?.isSubHeader ? 'column-reverse' : '',
        alignItems: isTablet && props?.isSubHeader ? 'center' : '',
        fontSize: isTablet && props?.isSubHeader ? '13px' : '',
        padding: isTablet && props?.isSubHeader ? '0px 15px' : '',
      }}
    >
      <StyledLink
        className={'desktop-nav-link-btn'}
        href={telLink}
        aria-label={finalAriaLabel}
        callCenterNoColor={props?.callCenterNoColor}
        isSubHeader={props?.isSubHeader}
      >
        <Text field={phoneNo} />
      </StyledLink>
      <div
        className={'img-div-link-btn'}
        style={{ marginLeft: '0.5rem', display: 'flex' }}
        onClick={() => {
          if (isEditorActive()) return;
          window.open(telLink);
        }}
      >
        <StNextImage
          suppressHydrationWarning
          className={'img-link-btn'}
          field={icon}
          height={24}
          width={24}
          overrideIconColor={props?.callCenterNoColor}
        />
      </div>
    </AppBarNavItem>
  );
  const MobileNavBar = () => (
    <MobileNavListItem data-testid="mobile-nav-bar" id="call-center" hoverColor={props.hoverColor}>
      <MobileNavIconWrap className={'img-link-btn-wrapper'}>
        <StNextImage
          suppressHydrationWarning
          className={'img-link-btn'}
          field={icon}
          height={16}
          width={16}
          overrideIconColor={props.callCenterNoColor}
        />
      </MobileNavIconWrap>
      <StyledLinkMobile
        className={'link-btn'}
        href={telLink}
        aria-label={finalAriaLabel}
        hoverColor={props.hoverColor}
        callCenterNoColor={props.callCenterNoColor}
      >
        <Text field={phoneNo} />
      </StyledLinkMobile>
    </MobileNavListItem>
  );

  const NormalText = () => <Text id="call-center" field={phoneNo} />;

  // Informs where the CallCenter Component is getting used or called from
  const parent = props.parent || CallCenterParent.TEXT;

  // Conditionally render respective component based on the parent prop
  const render = () => {
    const renders = {
      [CallCenterParent.DESKTOP_NAV_BAR]: <DesktopNavBar />,
      [CallCenterParent.MOBILE_NAV_BAR]: <MobileNavBar />,
      [CallCenterParent.TEXT]: <NormalText />,
    };
    return renders[parent] || renders.text;
  };
  return <>{phoneNo?.value ? render() : null}</>;
};

export default memo(CallCenter);
