/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTheme } from '@mui/material/styles';
import { StNextImage } from '../../../../components/shared/NextImage.styles';
import { useEffect, useRef, useState } from 'react';
import { AppBarNavItem } from '../HeaderNavigation.styles';
import { INavLinkField } from '../HeaderNavigation.type';
import { StNavLink } from '../NavLink';
import { LinkField } from '@sitecore-jss/sitecore-jss-react';
import getBaseURL from '../../../../lib/utils/getBaseURL';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { scrollToSectionPath } from '../../../../lib/utils/common';

type NavItemsType = {
  navLinks: Array<INavLinkField>;
  iconColor?: string;
  highlightColor?: string;
  linkColor?: string;
  hoverColor?: string;
};

const NavItems = (props: NavItemsType): JSX.Element => {
  const theme = useTheme();
  const [_selectedHeader, setSelectedHeader] = useState<string>('');
  const [updatedLinkFields, setUpdatedLinkFields] = useState<LinkField[]>([]);
  const [currentHash, setCurrentHash] = useState<any>('');
  const isNotificationBannerOpen = useSelector(
    (state: RootState) => state?.config?.isNotificationBannerOpen
  );

  const isBrowser = typeof window !== 'undefined';
  const sectionRefs = {
    section1: useRef(
      isBrowser ? (document?.getElementById('who-can-take-part') as HTMLElement) : <></>
    ),
    section2: useRef(isBrowser ? (document?.getElementById('faqs') as HTMLElement) : <></>),
    section3: useRef(isBrowser ? (document?.getElementById('about-study') as HTMLElement) : <></>),
    section4: useRef(
      isBrowser ? (document?.getElementById('study-location') as HTMLElement) : <></>
    ),
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentHash(window.location.hash);
      const handleHashChange = () => {
        setCurrentHash(window.location.hash);
      };
      window.addEventListener('hashchange', handleHashChange);
      return () => {
        window.removeEventListener('hashchange', handleHashChange);
      };
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    const margin = isNotificationBannerOpen ? 100 : 20;
    scrollToSectionPath(currentHash, margin, 1000);
  }, [currentHash]);

  useEffect(() => {
    const handleIntersection = (entries: any) => {
      let maxVisibleRatio = 0;
      let maxVisibleElement = null;

      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          if (entry.intersectionRatio >= maxVisibleRatio) {
            maxVisibleRatio = entry.intersectionRatio;
            maxVisibleElement = entry.target.id;
          }
        }
      });

      if (maxVisibleElement != null) {
        setSelectedHeader(maxVisibleElement);
      } else {
        setSelectedHeader('');
      }
    };

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: [0.5, 1],
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current as Element);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [sectionRefs]);

  const scrollToSection = (e: any, item: any) => {
    e.preventDefault();
    try {
      if (item) {
        const itemId = item?.value?.anchor;
        const targetElement = document.getElementById(itemId);
        const margin = isNotificationBannerOpen ? 100 : 20;
        if (targetElement) {
          const offsetTop = targetElement.offsetTop;
          window.scroll({
            top: offsetTop - margin,
            left: 0,
            behavior: 'smooth',
          });
        } else {
          window.location.href = item?.value?.href;
        }
      }
    } catch (_error) {}
  };

  useEffect(() => {
    const newArr: LinkField[] = [];
    props.navLinks.forEach((item) => {
      const baseurl = getBaseURL();
      let page = item?.fields?.link?.value?.href;
      if (page) {
        // Add Anchors
        if (item?.fields?.link?.value?.anchor) {
          page = page + `#${item?.fields?.link?.value?.anchor}`;
        }
        const newUrl = new URL(page, `${baseurl}`);
        const newLink: LinkField = {
          ...item?.fields?.link,
          value: {
            ...item?.fields?.link?.value,
            href: newUrl.toString(),
          },
        };
        newArr.push(newLink);
      }
    });
    setUpdatedLinkFields(newArr);
  }, [props.navLinks]);

  return (
    <>
      {Array.isArray(updatedLinkFields) &&
        updatedLinkFields.length > 0 &&
        updatedLinkFields.map((item, index) => (
          <AppBarNavItem key={index} onClick={(e) => scrollToSection(e, item)}>
            <StNavLink
              className={'nav-item-btn'}
              field={item}
              highlight={false}
              linkColor={props.linkColor}
              highlightColor={props.highlightColor}
            />
            <div
              style={{
                display: 'flex',
                marginLeft: '0.5rem',
              }}
              onClick={(e) => scrollToSection(e, item)}
            >
              <StNextImage
                suppressHydrationWarning
                className={'nav-img-item-btn'}
                field={props.navLinks[index].fields.icon}
                width={(props.navLinks[index]?.fields?.icon?.value?.width as number) || 16}
                height={(props.navLinks[index]?.fields?.icon?.value?.height as number) || 18}
                overrideIconColor={props.iconColor || theme.palette.themeColor.main}
              />
            </div>
          </AppBarNavItem>
        ))}
    </>
  );
};

export default NavItems;
