import * as React from 'react';
import { INavLinkField } from '../HeaderNavigation.type';
import { MobileNavListItem, MobileNavIconWrap } from '../HeaderNavigation.styles';
import { StMobNavLink } from '../NavLink';
import { StNextImage } from '../../../shared/NextImage.styles';
import { modalContext } from '../../../../Layout';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

const DrawerListItem: React.FC<INavLinkField> = (props) => {
  const modalState = React.useContext(modalContext);
  const isNotificationBannerOpen = useSelector(
    (state: RootState) => state?.config?.isNotificationBannerOpen
  );
  const { openModal } = modalState;
  const mobileLinkClickHandler = (event: React.SyntheticEvent) => {
    if (typeof props.handleDrawerToggle === 'function') {
      props.handleDrawerToggle();
    }
    const linkTypeValue = props?.fields?.link?.value?.linktype || '';
    if (linkTypeValue === 'external') {
      event.preventDefault();
      openModal(props.fields.link.value.href || '');
    }
    if (props.fields.link) {
      event.preventDefault();
      const itemId = props.fields.link?.value?.anchor as string;
      const item = document?.getElementById(itemId);

      if (item !== null) {
        const margin = isNotificationBannerOpen ? 100 : 20;
        const itemOffset = document.getElementById(itemId)?.offsetTop;
        if (itemOffset) {
          window.scroll({
            top: itemOffset - margin,
            left: 0,
            behavior: 'smooth',
          });
        }
      } else {
        window.location.href = props.fields.link?.value?.href as string;
      }
    }
  };
  return (
    <MobileNavListItem hoverColor={props.hoverColor}>
      <MobileNavIconWrap>
        <StNextImage
          suppressHydrationWarning
          onClick={mobileLinkClickHandler}
          className={'img-link-btn'}
          field={props.fields.icon}
          height={18}
          width={18}
          overrideIconColor={props.iconColor}
        />
      </MobileNavIconWrap>
      <StMobNavLink
        className={'mobile-link-btn'}
        field={props.fields.link}
        onClick={mobileLinkClickHandler}
        linkColor={props.linkColor}
        hoverColor={props.hoverColor}
      ></StMobNavLink>
    </MobileNavListItem>
  );
};
export default DrawerListItem;
