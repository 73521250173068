import * as React from 'react';
import List from '@mui/material/List';
import DrawerListItem from './DrawerListItem';
import { DrawerContentProps, INavLinkField } from '../HeaderNavigation.type';
import CallCenter from '../CallCenter/CallCenter';
import { CallCenterParent } from '../CallCenter/CallCenter.types';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import getBaseURL from '../../../../lib/utils/getBaseURL';

const DrawerContent: React.FC<DrawerContentProps> = (props) => {
  const isNotificationBannerOpen = useSelector(
    (state: RootState) => state.config.isNotificationBannerOpen
  );

  const [updatedLinkFields, setUpdatedLinkFields] = useState<INavLinkField[]>([]);

  useEffect(() => {
    const newArr: INavLinkField[] = [];
    props.navigationLinks.forEach((item) => {
      const baseurl = getBaseURL();
      let page = item?.fields?.link?.value?.href;
      if (page) {
        // Add Anchors
        if (item?.fields?.link?.value?.anchor) {
          page = page + `#${item?.fields?.link?.value?.anchor}`;
        }
        const newUrl = new URL(page, `${baseurl}`);
        const newLink: INavLinkField = {
          ...item,
          fields: {
            ...item.fields,
            link: {
              ...item.fields.link,
              value: {
                ...item?.fields?.link?.value,
                href: newUrl.toString(),
              },
            },
          },
        };
        newArr.push(newLink);
      }
    });
    setUpdatedLinkFields(newArr);
  }, [props.navigationLinks]);

  return (
    <List
      className="mobile-nav-list"
      sx={{
        paddingTop: isNotificationBannerOpen === true ? '60px' : '8px',
      }}
    >
      {updatedLinkFields &&
        updatedLinkFields.map((item: INavLinkField) => (
          <DrawerListItem
            key={item.id || ''}
            handleDrawerToggle={props.handleDrawerToggle}
            iconColor={props?.iconColor}
            linkColor={props.linkColor}
            hoverColor={props.hoverColor}
            {...item}
          />
        ))}
      {!isEditorActive() && (
        <CallCenter
          parent={CallCenterParent.MOBILE_NAV_BAR}
          callCenterNoColor={props.callCenterNoColor}
          hoverColor={props.hoverColor}
        />
      )}
    </List>
  );
};

export default DrawerContent;
