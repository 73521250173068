/* eslint-disable react-hooks/rules-of-hooks */
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useState, useEffect, memo } from 'react';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import CallCenter from './CallCenter/CallCenter';
import { CallCenterParent } from './CallCenter/CallCenter.types';
import { HeaderNavigationProps, INavLinkField, logoPositionValue } from './HeaderNavigation.type';
import { isEditorActive, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Logo from './Logo';
import MobileAppBar from './MobileAppBar/MobileAppBar';
import NavItems from './NavItems/NavItems';
import SubHeaderNavigation from './SubHeaderNavigation/SubHeaderNavigation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import delay from 'lodash/delay';
import { scrollToSectionPath } from '../../../lib/utils/common';

function HeaderNavigation(props: HeaderNavigationProps): JSX.Element {
  const theme = useTheme();
  const [currentHash, setCurrentHash] = useState<any>('');
  const { sitecoreContext } = useSitecoreContext();
  const isNotificationBannerOpen = useSelector(
    (state: RootState) => state?.config?.isNotificationBannerOpen
  );
  if (!props?.fields) return <></>;

  const isCallCenterVisible =
    (sitecoreContext?.route?.fields?.isCallCenterVisible as any)?.value || false;
  const navLinks = props?.fields?.navigationLinks as Array<INavLinkField>;
  const languageList = props?.fields?.languageList;
  const logoPosition = props?.fields?.logoPosition?.value || logoPositionValue.Left;
  const appBarFlexDirection = logoPosition === logoPositionValue.Left ? 'row' : 'row-reverse';

  const isHomePage = (sitecoreContext?.route?.fields?.isHomePage as any)?.value || false;

  const scrollToSection = (e: any, item: any) => {
    e.preventDefault();
    try {
      if (item) {
        const itemId = item?.value?.anchor;
        const targetElement = document.getElementById(itemId);
        const margin = isNotificationBannerOpen ? 100 : 80;
        if (targetElement) {
          const offsetTop = targetElement.offsetTop + 80;

          window.scroll({
            top: offsetTop - margin,
            left: 0,
            behavior: 'smooth',
          });
        } else {
          window.location.href = item?.value?.href;
        }
      }
    } catch (_error) {}
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentHash(window.location.hash);
      const handleHashChange = () => {
        setCurrentHash(window.location.hash);
      };
      window.addEventListener('hashchange', handleHashChange);
      return () => {
        window.removeEventListener('hashchange', handleHashChange);
      };
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    const delayTime = 2000;
    delay(() => scrollToSectionPath(currentHash, 40, delayTime), delayTime, 'later');
  }, [currentHash]);

  const NavBarDesktop = () => {
    return (
      <>
        <Box
          display="flex"
          flexDirection={appBarFlexDirection}
          justifyContent="space-between"
          sx={{
            width: '100%',
            marginLeft: '6rem',
            marginRight: '5rem',
            [theme.breakpoints.down(1220)]: {
              marginLeft: '4rem',
              marginRight: '3rem',
            },
            [theme.breakpoints.down(1150)]: {
              marginLeft: '3rem',
              marginRight: '2rem',
            },
            [theme.breakpoints.down(1080)]: {
              marginLeft: '1.5rem',
              marginRight: '0.5rem',
            },
            [theme.breakpoints.down(1040)]: {
              marginInline: '0.5rem',
            },
          }}
        >
          <Box>
            <Logo
              id={'dtp-desktop-logo'}
              imageField={props?.fields?.headerLogo}
              ariaLabel={props?.fields?.headerLogoAriaLabel?.value as string}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <NavItems
              navLinks={navLinks}
              iconColor={props?.fields?.iconColor?.value as string}
              linkColor={props?.fields?.linkColor?.value as string}
              hoverColor={props?.fields?.hoverColor?.value as string}
              highlightColor={props?.fields?.highlightColor?.value as string}
            />
            <Box>
              {isCallCenterVisible && !isEditorActive() && (
                <CallCenter
                  parent={CallCenterParent.DESKTOP_NAV_BAR}
                  callCenterNoColor={props?.fields?.callCenterNoColor?.value as string}
                  hoverColor={props?.fields?.hoverColor?.value as string}
                />
              )}
            </Box>
            {!isEditorActive() && (
              <LanguageSelector
                languageList={languageList}
                uniqueId="desktop-"
                languageDDColor={props?.fields?.languageDDColor?.value as string}
                isHomePage={isHomePage}
              />
            )}
          </Box>
        </Box>
      </>
    );
  };
  return !props?.fields?.isSubHeader?.value ? (
    <>
      {' '}
      <Box
        id="mobile-header"
        sx={{
          display: 'block',
          boxShadow: 'unset',
          [theme.breakpoints.up(1000)]: {
            display: 'none',
          },
        }}
      >
        <MobileAppBar {...props} />
      </Box>
      <Box
        id="desktop-header"
        sx={{
          boxShadow: 'unset',
          display: 'flex',
          [theme.breakpoints.up(1366)]: {
            width: '1366px',
            marginInline: 'auto',
          },
          [theme.breakpoints.down(1000)]: {
            display: 'none',
          },
          background: props?.fields?.bgColor?.value,
        }}
      >
        <NavBarDesktop />
      </Box>
    </>
  ) : (
    <>
      <SubHeaderNavigation
        navLinks={navLinks}
        scrollToSection={scrollToSection}
        languageList={languageList}
        CallCenterParent={CallCenterParent}
        hoverColor={props?.fields?.hoverColor?.value as string}
        isCallCenterVisible={isCallCenterVisible}
      />
    </>
  );
}
export default memo(HeaderNavigation);
