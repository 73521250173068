import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LanguageSelector from '../../LanguageSelector/LanguageSelector';
import CallCenter from '../CallCenter/CallCenter';
import NavItemsSubHeader from './NavItemsSubHeader';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { CallCenterParent } from '../CallCenter/CallCenter.types';

const SubHeaderNavigation = (props: any): JSX.Element => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down(768));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { sitecoreContext } = useSitecoreContext();
  const isHomePage = (sitecoreContext?.route?.fields?.isHomePage as any)?.value || false;

  return (
    <Box sx={{ background: '#f7f8fa' }}>
      <Box
        sx={{
          boxShadow: 'unset',
          display: 'flex',
          [theme.breakpoints.up(1366)]: {
            width: '1366px',
            marginInline: 'auto',
          },
          background: '#f7f8fa',
          borderBottom: '1px solid #d9d9d9',
        }}
      >
        <Box
          sx={{
            width: '100%',
            marginRight: '4rem',
            marginLeft: '6rem',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent={isHomePage || isMobile ? 'center' : 'flex-end'}
          >
            {!isNotDesktop && (
              <>
                <NavItemsSubHeader
                  navLinks={props.navLinks}
                  scrollToSection={props.scrollToSection}
                  isNotDesktop={isNotDesktop}
                  languageList={props.languageList}
                />
                <Box>
                  {props?.isCallCenterVisible && (
                    <CallCenter
                      parent={CallCenterParent.DESKTOP_NAV_BAR}
                      isSubHeader={true}
                      isNotDesktop={isNotDesktop}
                      hoverColor={props?.hoverColor as string}
                    />
                  )}
                </Box>
                <Divider
                  orientation="vertical"
                  sx={{ color: theme.palette.neutral4.main, height: '48px', width: '1px' }}
                />
              </>
            )}
            {
              <Box
                sx={
                  isNotDesktop
                    ? { height: '48px', justifyContent: 'center', alignContent: 'center' }
                    : {}
                }
              >
                <LanguageSelector
                  languageList={props.languageList}
                  uniqueId="desktop-"
                  isSubHeader={true}
                  isHomePage={isHomePage}
                />
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SubHeaderNavigation;
