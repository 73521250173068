import { ImageField, LinkField, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

export interface INavLinkField {
  id: string;
  fields: {
    icon: ImageField;
    link: LinkField;
  };
  handleDrawerToggle?: () => void;
  iconColor?: string;
  hoverColor?: string;
  linkColor?: string;
}

export enum logoPositionValue {
  Left = 'Left',
  Right = 'Right',
}

export type logoPosition = {
  value: logoPositionValue;
};

interface ILanguageListField {
  languageCode: string;
  displayName: string;
  nativeName: string;
  flagIcon: string;
  countryName: string;
  countryISO2: string;
  countryISO3: string;
}

export type HeaderNavigationProps = ComponentProps & {
  fields: {
    headerLogo: ImageField;
    navigationLinks: Array<INavLinkField>;
    logoPosition: logoPosition;
    languageList: Array<ILanguageListField>;
    headerLogoAriaLabel: TextField;
    openMenuAriaLabel: TextField;
    closeButtonAriaLabel: TextField;
    iconColor?: TextField;
    highlightColor?: TextField;
    linkColor?: TextField;
    hoverColor?: TextField;
    bgColor?: TextField;
    callCenterNoColor?: TextField;
    languageDDColor?: TextField;
    isSubHeader?: any;
  };
};

export type NavLinkProps = {
  link: LinkField;
  icon?: ImageField;
  iconHeight?: string | number | undefined;
  iconWidth?: string | number | undefined;
};

export type DrawerContentProps = {
  navigationLinks: Array<INavLinkField>;
  handleDrawerToggle: () => void;
  iconColor?: string;
  hoverColor?: string;
  linkColor?: string;
  callCenterNoColor?: string;
};
