import { TextField, ImageField } from '@sitecore-jss/sitecore-jss-react';
export enum CallCenterParent {
  TEXT = 'text',
  DESKTOP_NAV_BAR = 'desktopNavBar',
  MOBILE_NAV_BAR = 'mobileNavBar',
}
export interface ICallCenter {
  parent: CallCenterParent;
  callCenterNoColor?: string;
  hoverColor?: string;
  isSubHeader?: boolean;
  isNotDesktop?: boolean;
}
export interface ICallCenterContext {
  callCenterDefaultValue?: TextField;
  callCenterLength?: TextField;
  callCenterPrefixDailingcode?: TextField;
  callCenterAriaLabel?: TextField;
  callCenterFormat?: TextField;
  callCenterIcon?: ImageField;
}
