import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

export const AppBarNavItemSubHeader = styled(Box)(({ theme }) => ({
  color: theme.palette.themeColor.main,
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  display: 'flex',
  cursor: 'pointer',
  fontWeight: 580,
  textDecoration: 'none',
  // '&:hover': {
  //   textDecoration: 'none',
  //   borderBottom: '1px dashed',
  //   color: `${theme.palette.themeColor.main}`,
  // },
  [theme.breakpoints.down(1220)]: {
    marginInline: '0.8rem',
  },
  [theme.breakpoints.down(1150)]: {
    marginInline: '0.5rem',
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.neutral4.main,
  width: '100%',
  height: '1px',
  borderRadius: '50px',
}));
