import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import HamburgerIcon from '../../../shared/HamburgerIcon/HamburgerIcon';
import CloseIcon from '../../../shared/CloseIcon/CloseIcon';
import { HeaderNavigationProps, logoPositionValue } from '../HeaderNavigation.type';
import Logo from '../Logo';
import Box from '@mui/material/Box';
import DrawerContent from './DrawerContent';
import LanguageSelector from '../../LanguageSelector/LanguageSelector';
import { isEditorActive, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

const drawerWidth = '256px';
const AppBarWithDrawer: React.FC<HeaderNavigationProps> = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const languageList = props.fields?.languageList;
  const logoPosition = props.fields?.logoPosition?.value || logoPositionValue.Left;
  const appBarFlexDirection = logoPosition === logoPositionValue.Left ? 'row' : 'row-reverse';
  const { sitecoreContext } = useSitecoreContext();
  const isCallCenterVisible =
    (sitecoreContext?.route?.fields?.isCallCenterVisible as any)?.value || false;

  const isHomePage = (sitecoreContext?.route?.fields?.isHomePage as any)?.value || false;

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  if (!props?.fields) return <></>;
  return (
    <>
      <Toolbar>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexGrow: 1,
            flexDirection: appBarFlexDirection,
            alignItems: 'center',
          }}
        >
          <Logo
            id={'dtp-mobile-logo'}
            isMobile={true}
            imageField={props.fields.headerLogo}
            ariaLabel={props?.fields?.headerLogoAriaLabel?.value as string}
          />
          <div style={{ display: 'flex', flexDirection: appBarFlexDirection }}>
            {!isEditorActive() && (
              <LanguageSelector
                languageList={languageList}
                uniqueId="mobile-"
                languageDDColor={props?.fields?.languageDDColor?.value as string}
                isHomePage={isHomePage}
              />
            )}
            {(props.fields?.navigationLinks?.length > 0 || isCallCenterVisible) && (
              <IconButton
                data-testid="mobile-app-bar"
                color="inherit"
                aria-label={
                  isDrawerOpen
                    ? (props?.fields?.closeButtonAriaLabel?.value as string)
                    : (props?.fields?.openMenuAriaLabel?.value as string)
                }
                edge="start"
                onClick={handleDrawerToggle}
              >
                {isDrawerOpen ? (
                  <CloseIcon color={props?.fields?.iconColor?.value as string} />
                ) : (
                  <HamburgerIcon color={props?.fields?.iconColor?.value as string} />
                )}
              </IconButton>
            )}
          </div>
        </Box>
      </Toolbar>
      {/* </AppBar> */}

      <Drawer
        variant="temporary"
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        elevation={0}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        {/* Drawer Content */}
        <Toolbar />
        <DrawerContent
          navigationLinks={props.fields.navigationLinks}
          handleDrawerToggle={handleDrawerToggle}
          iconColor={props?.fields?.iconColor?.value as string}
          linkColor={props?.fields?.linkColor?.value as string}
          hoverColor={props?.fields?.hoverColor?.value as string}
          callCenterNoColor={props?.fields?.callCenterNoColor?.value as string}
        />
      </Drawer>
      {/* <Toolbar /> */}
    </>
  );
};

export default AppBarWithDrawer;
