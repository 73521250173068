import { styled } from '@mui/material/styles';
import LinkWithModal from '../../shared/LinkWithModal';
import { Link } from '@sitecore-jss/sitecore-jss-nextjs';

type highlightProps = {
  highlight: boolean;
  linkColor?: string;
  highlightColor?: string;
};
export const StNavLink = styled(LinkWithModal, {
  shouldForwardProp: (prop) =>
    prop !== 'highlight' && prop !== 'linkColor' && prop !== 'highlightColor',
})<highlightProps>(({ theme, highlight, linkColor, highlightColor }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: highlight
    ? highlightColor || theme.palette.primary.main
    : linkColor || `${theme.palette.themeColor.main}`,
  '&:hover': {
    textDecoration: 'none',
    borderBottom: '1px dashed',
    color: `${theme.palette.themeColor.main}`,
  },
}));

type MobNavLinkProps = {
  linkColor?: string;
  hoverColor?: string;
};
// Used in Mobile drawer
export const StMobNavLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'linkColor' && prop !== 'hoverColor',
})<MobNavLinkProps>(({ theme, linkColor, hoverColor }) => ({
  textDecoration: 'none',
  color: linkColor || `${theme.palette.themeColor.main}`,
  textAlign: 'inherit',
  lineHeight: 'inherit',
  fontWeight: '600',
  '&:hover': {
    textDecoration: 'none',
    borderBottom: `1px dashed ${hoverColor || theme.palette.themeColor.main}`,
  },
}));
