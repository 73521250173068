/* eslint-disable @typescript-eslint/no-unused-vars */
import Divider from '@mui/material/Divider';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useRef, useState } from 'react';
import { StNextImage } from '../../../../components/shared/NextImage.styles';
// import { INavLinkField } from '../HeaderNavigation.type';
// import { StNavLink } from '../NavLink';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { getCookie } from 'cookies-next';
import getBaseURL from '../../../../lib/utils/getBaseURL';
import LinkwithModal from '../../../shared/LinkWithModal';
import { AppBarNavItemSubHeader } from '../SubHeaderNavigation/SubHeaderNavigation.styles';

export interface ILanguageListField {
  languageCode: string;
  displayName: string;
  nativeName: string;
  flagIcon: string;
  countryName: string;
  countryISO2: string;
  countryISO3: string;
}
// type NavItemsType = {
//   navLinks: Array<INavLinkField>;
//   scrollToSection: (e: any, item: any) => void;
//   isNotDesktop?: boolean;
//   languageList: ILanguageListField[];
// };

export const StDiv = styled('div')(({ theme }) => ({
  '&:hover': {
    textDecoration: 'none',
    borderBottom: '1px dashed',
    color: `${theme.palette.themeColor.main}`,
  },
}));

const StNavLink = styled(LinkwithModal, {
  shouldForwardProp: (prop) =>
    prop !== 'highlight' && prop !== 'linkColor' && prop !== 'highlightColor',
})<any>(({ theme, highlight, linkColor, highlightColor }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: highlight
    ? highlightColor || theme.palette.primary.main
    : linkColor || `${theme.palette.themeColor.main}`,
}));

const NavItemsSubHeader = (props: any): JSX.Element => {
  const theme = useTheme();
  const [selectedHeader, setSelectedHeader] = useState<string>('');
  const [updatedLinkFields, setUpdatedLinkFields] = useState<LinkField[]>([]);
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isBrowser = typeof window !== 'undefined';
  const eprName = getCookie('epr-name');
  const sectionRefs = {
    section1: useRef(
      isBrowser ? (document?.getElementById('who-can-take-part') as HTMLElement) : <></>
    ),
    section2: useRef(isBrowser ? (document?.getElementById('faqs') as HTMLElement) : <></>),
    section3: useRef(isBrowser ? (document?.getElementById('about-study') as HTMLElement) : <></>),
  };

  useEffect(() => {
    const handleIntersection = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          setSelectedHeader(entry.target.id);
        }
      });
    };

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleIntersection, observerOptions);
    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current as Element);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const newArr: LinkField[] = [];
    props.navLinks.forEach((item: any) => {
      const baseurl = getBaseURL();
      let page = getCookie('epr-name') || item?.fields?.link?.value?.href;
      if (page) {
        // Add Anchors
        if (item?.fields?.link?.value?.anchor) {
          page = page + `#${item?.fields?.link?.value?.anchor}`;
        }
        const newUrl = new URL(page, `${baseurl}`);
        const newLink: LinkField = {
          ...item?.fields?.link,
          value: {
            ...item?.fields?.link?.value,
            href: newUrl.toString(),
          },
        };
        newArr.push(newLink);
      }
    });
    setUpdatedLinkFields(newArr);
  }, [props.navLinks, eprName]);

  return (
    <>
      {Array.isArray(updatedLinkFields) &&
        updatedLinkFields.length > 0 &&
        updatedLinkFields.map((item: any, index: number) => (
          <React.Fragment key={index}>
            <AppBarNavItemSubHeader
              key={index}
              onClick={(e) => props.scrollToSection(e, item)}
              sx={{
                justifyContent: index === 0 ? 'flex-start' : 'center',
              }}
            >
              <StDiv
                style={
                  isTablet
                    ? {
                        display: 'flex',
                        flexFlow: 'column-reverse',
                        alignItems: 'center',
                        fontSize: '13px',
                      }
                    : { display: 'flex' }
                }
              >
                <StNavLink
                  field={item}
                  highlight={false}
                  linkColor={props.linkColor}
                  highlightColor={props.highlightColor}
                />
                <div
                  style={{
                    display: 'flex',
                    marginLeft: '0.5rem',
                  }}
                  onClick={(e) => props.scrollToSection(e, item)}
                >
                  <StNextImage
                    suppressHydrationWarning
                    className={'nav-img-item-btn'}
                    field={props.navLinks[index]?.fields?.icon}
                    width={(props.navLinks[index]?.fields?.icon?.value?.width as number) || 16}
                    height={(props.navLinks[index]?.fields?.icon?.value?.height as number) || 18}
                    overrideIconColor={props.iconColor || theme.palette.themeColor.main}
                  />
                </div>
              </StDiv>
            </AppBarNavItemSubHeader>
            <Divider
              orientation="vertical"
              sx={{ color: theme.palette.neutral4.main, height: '48px', width: '1px' }}
            ></Divider>
          </React.Fragment>
        ))}
    </>
  );
};

export default NavItemsSubHeader;
